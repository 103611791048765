<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">CIERRE REQUERIMIENTOS</span></v-toolbar>
    <v-card-text class="pa-4" v-if="registro.emailstatus">
        <div v-for="(item, index) in registro.emailstatus" :key="index">
          <lectura_logs_CierreComponent :item="item" :index="index" :colores="colores"></lectura_logs_CierreComponent>
          <br>
        </div>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
// import lecturaLogs from '@/components/crm/lectura_logsComponent.vue'
export default {
  name: 'lectura_escalamientosComponent',
  components: {
    lectura_logs_CierreComponent: () => import('@/components/crm/lectura_logs_CierreComponent.vue')
  },
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  watch: {
    registro: {
      immediate: true,
      handler (newVal, oldVal) {
        if (this.registro.gesnotificacion != null) {
          this.registro.emailstatus.forEach((element1, index) => {
            this.registro.emailstatus[index].codigoCorreo = 0
            this.registro.gesnotificacion.forEach(element2 => {
              if (element1.gestion_id === element2.gestion_id && element1.correo === element2.correo && element1.listado_id === element2.tipo_id) {
                this.registro.emailstatus[index].codigoCorreo = element2.correo_id
              }
            })
          })
        }
      }
    }
  },
  methods: {

  }

}
</script>
